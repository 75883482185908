<template>
  <div class="inner-home">
    <div class="hero">
      <VueSlickCarousel
        v-if="slides.length > 0"
        v-bind="{
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
          autoplay: false,
          speed: 800,
          fade: true,
          lazyLoad: 'ondemand'
        }"
        ref="slickCategory"
      >
        <template v-for="(slide, index) in slides">
          <div class="item clearix" :class="index" :key="index">
            <div class="caption">
              <div class="wrapper">
                <div class="holder">
                  <h2 v-html="slide.title"></h2>
                  <div class="txt">
                    <p v-html="slide.description"></p>
                  </div>
                  <!-- end of txt -->
                </div>
              </div>
              <!-- end of wrapper -->
            </div>
            <!-- end of caption -->
            <figure>
              <img :src="slide.image" />
            </figure>
          </div>
          <!-- end of item -->
        </template>
      </VueSlickCarousel>
      <!-- end of slider -->

      <div class="search">
        <div class="wrapper">
          <form method="GET" :action="`/find-property`" ref="formSearch">
            <input type="hidden" name="province" v-model="form.province" />
            <input type="hidden" name="city" v-model="form.city" />
            <input type="hidden" name="area" v-model="form.area" />
            <input type="hidden" name="status" v-model="form.status" />
            <input type="hidden" name="bathroom" v-model="form.bathroom" />
            <input type="hidden" name="carlot" v-model="form.carlot" />
            <input
              type="hidden"
              name="minRentPrice"
              v-model="form.minRentPrice"
            />
            <input
              type="hidden"
              name="maxRentPrice"
              v-model="form.maxRentPrice"
            />
            <input
              type="hidden"
              name="minSalesPrice"
              v-model="form.minSalesPrice"
            />
            <input
              type="hidden"
              name="maxSalesPrice"
              v-model="form.maxSalesPrice"
            />
            <input
              type="hidden"
              name="minLandSize"
              v-model="form.minLandSize"
            />
            <input
              type="hidden"
              name="maxLandSize"
              v-model="form.maxLandSize"
            />
            <input
              type="hidden"
              name="minBuildingSize"
              v-model="form.minBuildingSize"
            />
            <input
              type="hidden"
              name="maxBuildingSize"
              v-model="form.maxBuildingSize"
            />

            <div class="type">
              <label>
                <input
                  type="radio"
                  name="transactionType"
                  value="1"
                  @click="$refs.slickCategory.goTo(0)"
                  v-model="form.transactionType"
                />
                <span>{{ $t("home.section1.Tab1") }}</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="transactionType"
                  value="2"
                  @click="$refs.slickCategory.goTo(1)"
                  v-model="form.transactionType"
                />
                <span>{{ $t("home.section1.Tab2") }}</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="transactionType"
                  value="3"
                  @click="$refs.slickCategory.goTo(2)"
                  v-model="form.transactionType"
                />
                <span>{{ $t("home.section1.Tab3") }}</span>
              </label>
            </div>
            <!-- end of type -->
            <div class="query">
              <i class="cvr-bg"></i>
              <input
                type="text"
                name="search"
                v-model="form.search"
                :placeholder="$t('home.section1.PlaceHolder')"
              />
              <button type="submit" class="button" style="min-width: 115px">
                {{ $t("home.section1.Button") }}
              </button>
            </div>
            <!-- end of query -->
            <div
              class="amenities"
              :class="{
                rent: form.transactionType == 1,
                'rent-to-own': form.transactionType == 2,
                buy: form.transactionType == 3
              }"
            >
              <fieldset class="property-type">
                <select v-model="form.propertyType" name="propertyType">
                  <option :value="null">
                    {{ $t("home.section1.PropertyType") }}
                  </option>
                  <option
                    v-for="(property, index) in propertyType"
                    :key="index"
                    :value="property.id"
                  >
                    {{ property.name }}
                  </option>
                </select>
              </fieldset>
              <fieldset class="bedrooms">
                <select
                  v-model="form.bedroom"
                  name="bedroom"
                  :disabled="disableBedRoom"
                >
                  <option :value="null">
                    {{ $t("home.section1.Bedrooms") }}
                  </option>
                  <option
                    v-for="(bedroom, index) in bedrooms"
                    :key="index"
                    :value="bedroom.value"
                  >
                    {{ bedroom.name }}
                  </option>
                </select>
              </fieldset>
              <fieldset class="furnish-type">
                <select v-model="form.furnishedType" name="furnishedType">
                  <option :value="null">
                    {{ $t("home.section1.FurnishType") }}
                  </option>
                  <option
                    v-for="(furnished, index) in furnishedType"
                    :key="index"
                    :value="furnished.id"
                  >
                    {{ furnished.name }}
                  </option>
                </select>
              </fieldset>
              <fieldset class="rent-period">
                <select v-model="form.rentPeriod" name="rentPeriod">
                  <option :value="null">
                    {{ $t("home.section1.RentPeriod") }}
                  </option>
                  <option
                    v-for="(period, index) in rentPeriod"
                    :key="index"
                    :value="period.name"
                  >
                    {{ period.name }}
                  </option>
                </select>
              </fieldset>
              <fieldset>
                <a
                  href="javascript:;"
                  data-src="#popup-addprop"
                  class="cvr-bg-af additional-filter"
                  data-fancybox
                >
                  {{ $t("findProperty.FormSearch.AdditionalFilterButton") }}
                </a>
              </fieldset>
            </div>
            <!-- end of amenities-->
            <div
              id="popup-addprop"
              class="popup-addprop"
              style="display: none;"
            >
              <h3>
                {{ $t("findProperty.FormSearch.AdditionalFilterTitle") }}
              </h3>
              <perfect-scrollbar>
                <div class="fgroup">
                  <label>
                    {{ $t("findProperty.PopUpFilter.Province") }}
                  </label>
                  <fieldset>
                    <select v-model="form.province" @change="getCities">
                      <option></option>
                      <option
                        v-for="(province, index) in provinces"
                        :key="index"
                        :value="province.name"
                      >
                        {{ province.name }}
                      </option>
                    </select>
                  </fieldset>
                </div>
                <!-- end of fgroup -->
                <div class="fgroup">
                  <label>
                    {{ $t("findProperty.PopUpFilter.City") }}
                  </label>
                  <fieldset>
                    <select v-model="form.city">
                      <option></option>
                      <option
                        v-for="(city, index) in cities"
                        :key="index"
                        :value="city.city"
                      >
                        {{ city.city }}
                      </option>
                    </select>
                  </fieldset>
                </div>
                <!-- end of fgroup -->
                <template v-if="form.transactionType !== null">
                  <div class="fgroup" v-if="form.transactionType <= 2">
                    <label>
                      {{ $t("findProperty.FormSearch.MonthlyRentPrice") }}
                    </label>
                    <div class="gap">
                      <div class="field-size">
                        <b class="toleft">Rp</b>
                        <input
                          type="text"
                          v-model="form.minRentPrice"
                          placeholder="Minimum"
                          class="price"
                          @keyup="formatRupiah('minRentPrice')"
                        />
                      </div>
                      <!-- end of field min rent price -->
                      <div class="field-size">
                        <b class="toleft">Rp</b>
                        <input
                          type="text"
                          v-model="form.maxRentPrice"
                          placeholder="Maximum"
                          class="price"
                          @keyup="formatRupiah('maxRentPrice')"
                        />
                      </div>
                      <!-- end of field max rent price -->
                    </div>
                    <!-- end of gap -->
                  </div>
                  <div class="fgroup" v-if="form.transactionType == 2">
                    <label>
                      {{ $t("findProperty.FormSearch.SalesPrice") }}
                    </label>
                    <div class="gap">
                      <div class="field-size">
                        <b class="toleft">Rp</b>
                        <input
                          type="text"
                          v-model="form.minSalesPrice"
                          placeholder="Minimum"
                          class="price"
                          @keyup="formatRupiah('minSalesPrice')"
                        />
                      </div>
                      <!-- end of field min rent price -->
                      <div class="field-size">
                        <b class="toleft">Rp</b>
                        <input
                          type="text"
                          v-model="form.maxSalesPrice"
                          placeholder="Maximum"
                          class="price"
                          @keyup="formatRupiah('maxSalesPrice')"
                        />
                      </div>
                      <!-- end of field max rent price -->
                    </div>
                    <!-- end of gap -->
                  </div>
                  <div class="fgroup" v-if="form.transactionType == 3">
                    <label>
                      {{ $t("findProperty.FormSearch.SalesPrice") }}
                    </label>
                    <div class="gap">
                      <div class="field-size">
                        <b class="toleft">Rp</b>
                        <input
                          type="text"
                          v-model="form.minSalesPrice"
                          placeholder="Minimum"
                          class="price"
                          @keyup="formatRupiah('minSalesPrice')"
                        />
                      </div>
                      <!-- end of field min rent price -->
                      <div class="field-size">
                        <b class="toleft">Rp</b>
                        <input
                          type="text"
                          v-model="form.maxSalesPrice"
                          placeholder="Maximum"
                          class="price"
                          @keyup="formatRupiah('maxSalesPrice')"
                        />
                      </div>
                      <!-- end of field max rent price -->
                    </div>
                    <!-- end of gap -->
                  </div>
                </template>
                <div class="fgroup">
                  <label>{{ $t("findProperty.PopUpFilter.LandSize") }}</label>
                  <div class="gap">
                    <div class="field-size">
                      <input
                        type="number"
                        min="0"
                        step="1"
                        v-model="form.minLandSize"
                        value=""
                        placeholder="Minimum"
                      />
                      <b>m <span class="meter-persegi">2</span></b>
                    </div>
                    <!-- end of field size -->
                    <div class="field-size">
                      <input
                        type="number"
                        min="0"
                        step="1"
                        v-model="form.maxLandSize"
                        value=""
                        placeholder="Maximum"
                      />
                      <b>m <span class="meter-persegi">2</span></b>
                    </div>
                    <!-- end of field size -->
                  </div>
                  <!-- end of gap -->
                </div>
                <!--end of fgroup -->
                <div class="fgroup">
                  <label>
                    {{ $t("findProperty.PopUpFilter.BuildingSize") }}
                  </label>
                  <div class="gap">
                    <div class="field-size">
                      <input
                        type="number"
                        min="0"
                        step="1"
                        v-model="form.minBuildingSize"
                        value=""
                        placeholder="Minimum"
                      />
                      <b>m <span class="meter-persegi">2</span></b>
                    </div>
                    <!-- end of field size -->
                    <div class="field-size">
                      <input
                        type="number"
                        min="0"
                        step="1"
                        v-model="form.maxBuildingSize"
                        value=""
                        placeholder="Maximum"
                      />
                      <b>m <span class="meter-persegi">2</span></b>
                    </div>
                    <!-- end of field size -->
                  </div>
                  <!-- end of gap -->
                </div>
                <!-- end of fgrop -->
                <div class="fgroup">
                  <label>Number of Bathrooms</label>
                  <div class="field-check">
                    <label
                      ><input
                        type="checkbox"
                        name="bath"
                        value="1"
                        v-model="form.bathroom"
                      /><span>1</span></label
                    >
                    <label
                      ><input
                        type="checkbox"
                        name="bath"
                        value="2"
                        v-model="form.bathroom"
                      /><span>2</span></label
                    >
                    <label
                      ><input
                        type="checkbox"
                        name="bath"
                        value="3"
                        v-model="form.bathroom"
                      /><span>3</span></label
                    >
                    <label
                      ><input
                        type="checkbox"
                        name="bath"
                        value="4+"
                        v-model="form.bathroom"
                      /><span>4+</span></label
                    >
                  </div>
                </div>
                <!--end of fgroup -->
                <div class="fgroup">
                  <label>Number of Car Lots</label>
                  <div class="field-check">
                    <label
                      ><input
                        type="checkbox"
                        name="carlot"
                        value="1"
                        v-model="form.carlot"
                      /><span>1</span></label
                    >
                    <label
                      ><input
                        type="checkbox"
                        name="carlot"
                        value="2"
                        v-model="form.carlot"
                      /><span>2</span></label
                    >
                    <label
                      ><input
                        type="checkbox"
                        name="carlot"
                        value="3"
                        v-model="form.carlot"
                      /><span>3</span></label
                    >
                    <label
                      ><input
                        type="checkbox"
                        name="carlot"
                        value="4+"
                        v-model="form.carlot"
                      /><span>4+</span></label
                    >
                  </div>
                </div>
                <!--end of fgroup -->
                <div class="fgroup">
                  <label>Status</label>
                  <div class="field-check">
                    <label
                      ><input
                        type="checkbox"
                        name="status"
                        value="primary"
                        v-model="form.status"
                      /><span>Primary</span></label
                    >
                    <label
                      ><input
                        type="checkbox"
                        name="status"
                        value="secondary"
                        v-model="form.status"
                      /><span>Secondary</span></label
                    >
                  </div>
                </div>
                <!--end of fgroup -->
              </perfect-scrollbar>
              <div class="toright">
                <a href="#" class="close" @click="resetPopUpForm">Reset</a>
                <a
                  href="#"
                  class="button close"
                  @click="$refs.formSearch.submit()"
                >
                  {{ $t("findProperty.PopUpFilter.Btn") }}
                </a>
              </div>
            </div>
          </form>
        </div>
        <!-- end of wrapper -->
      </div>
      <!-- end of search -->
    </div>
    <!-- end of hero -->

    <div class="copytxt has-slider">
      <div class="wrapper rowflex">
        <figure>
          <img :src="section1.image" />
          <div class="slider">
            <VueSlickCarousel
              v-if="section1.hasOwnProperty('carousel')"
              v-bind="{
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false,
                autoplay: true,
                speed: 800,
                fade: true,
                autoplaySpeed: 5000
              }"
            >
              <template v-for="(content, index) in section1.carousel">
                <div class="item" :key="index">
                  <div class="txt">
                    <p>{{ content.description }}</p>
                  </div>
                  <!--e nd of txt -->
                  <h3>{{ content.title }}</h3>
                </div>
              </template>
            </VueSlickCarousel>
          </div>
        </figure>
        <div class="caption">
          <h2>{{ section1.title }}</h2>
          <div class="txt" v-html="section1.description"></div>
          <!-- end of txt -->
          <div class="action">
            <router-link to="/find-property?transactionType=2" class="button">
              {{ $t("home.section2.Button1") }}
            </router-link>
            <router-link to="/about-us/rent-to-own" class="button btn-hollow">
              {{ $t("home.section2.Button2") }}
            </router-link>
          </div>
          <!-- end of action -->
        </div>
        <!-- end of caption -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of copytxt -->

    <div class="copytxt img-right">
      <div class="wrapper rowflex">
        <figure>
          <img :src="section2.image" />
        </figure>
        <div class="caption">
          <h2 style="font-size: 35px">{{ section2.title }}</h2>
          <div class="txt" v-html="section2.description"></div>
          <!-- end of txt -->
          <div class="action">
            <router-link to="/about-us/why-rubii" class="button btn-hollow">
              {{ $t("home.section3.Button2") }}
            </router-link>
            <router-link to="/contact-us" class="button">
              {{ $t("home.section3.Button1") }}
            </router-link>
          </div>
          <!-- end of action -->
        </div>
        <!-- end of caption -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of copy -->

    <div class="property">
      <div class="list">
        <div class="wrapper">
          <h2 class="clearfix">
            {{ $t("home.section4.Title1") }}
            <router-link to="/find-property?transactionType=2">
              {{ $t("home.section4.Link") }}
            </router-link>
          </h2>
          <div class="slider rto">
            <VueSlickCarousel
              ref="property1"
              v-if="property.rentToOwn.length"
              v-bind="{
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,
                autoplay: false,
                speed: 500,
                autoplaySpeed: 5000,
                variableWidth: false,
                responsive: [
                  {
                    breakpoint: 1400,
                    settings: {
                      arrows: false,
                      slidesToShow: 1,
                      variableWidth: false
                    }
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      arrows: false,
                      slidesToShow: 1,
                      variableWidth: true
                    }
                  }
                ]
              }"
            >
              <template v-for="(property, index) in property.rentToOwn">
                <div class="item item-property" :key="index">
                  <figure>
                    <router-link
                      v-if="property.propertyType"
                      :to="`/property/${property.slug}#rent-to-own`"
                      class="type"
                      :style="
                        `background-color: ${property.propertyType.class}`
                      "
                    >
                      {{ property.propertyType.name }}
                    </router-link>
                    <a
                      @click="bookmark(property.id)"
                      class="cvr-bg pointer bookmark"
                      :class="{ marked: property.isBookmarked }"
                    ></a>
                    <router-link :to="`/property/${property.slug}#rent-to-own`">
                      <template
                        v-if="
                          property.mediaDescription === null ||
                            property.mediaDescription.length <= 0
                        "
                      >
                        <img src="/img/dummy/home/1.jpg" />
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            checkType(property.mediaDescription[0]) == 'video'
                          "
                        >
                          <video style="width: 100%" controls autoplay>
                            <source
                              :src="property.mediaDescription[0]"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </template>
                        <template v-else>
                          <img :src="property.mediaDescription[0]" />
                        </template>
                      </template>
                    </router-link>
                  </figure>
                  <div class="caption">
                    <h3>
                      <router-link
                        :to="`/property/${property.slug}#rent-to-own`"
                      >
                        {{ property.name }}
                      </router-link>
                    </h3>
                    <div class="location cvr-bg-bf">
                      <span>{{ property.region }}, {{ property.city }}</span>
                      <span v-if="property.furnishedType">
                        {{ property.furnishedType.name }}
                      </span>
                    </div>
                    <!-- end of location -->
                    <strong class="price">
                      <b v-if="property.ratesAfterCalulate !== undefined">
                        Rp.
                        {{
                          property.ratesAfterCalulate.ratePerMonth
                            | convertToRupiah
                        }}
                      </b>
                      <small> / {{ $t("findProperty.Month") }}</small>
                      <em v-if="property.ratesAfterCalulate !== undefined">
                        Rp.
                        {{
                          property.ratesAfterCalulate
                            .propertyPriceEndOfRentalPeriod | convertToRupiah
                        }}
                      </em>
                    </strong>
                    <div class="amen">
                      <span
                        class="cvr-bg-bf ic-bed"
                        v-if="showBedAndBath(property.propertyTypeId)"
                      >
                        {{ property.numBedroom }}
                      </span>
                      <span
                        class="cvr-bg-bf ic-bath"
                        v-if="showBedAndBath(property.propertyTypeId)"
                      >
                        {{ property.numBathroom }}
                      </span>
                      <span class="cvr-bg-bf ic-land">
                        {{ property.landSize | convertToRupiah }} m<sup>2</sup>
                      </span>
                      <span class="cvr-bg-bf ic-build">
                        {{ property.buildingSize | convertToRupiah }} m
                        <sup>2</sup>
                      </span>
                    </div>
                    <!-- end of amen -->
                  </div>
                </div>
                <!-- end of item -->
              </template>
            </VueSlickCarousel>
            <!--end of slick -->
            <div class="slidenav">
              <a
                class="prev pointer cvr-bg"
                @click="$refs.property1.prev()"
              ></a>
              <a
                class="next pointer cvr-bg"
                @click="$refs.property1.next()"
              ></a>
            </div>
          </div>
          <!-- end of slider -->
          <div class="action">
            <router-link to="/find-property?transactionType=2" class="button">
              {{ $t("home.section4.Button") }}
            </router-link>
          </div>
        </div>
        <!-- end of wrapper -->
      </div>
      <div class="list">
        <div class="wrapper">
          <h2 class="clearfix">
            {{ $t("home.section4.Title2") }}
            <router-link to="/find-property?transactionType=1">
              {{ $t("home.section4.Link") }}
            </router-link>
          </h2>
          <div class="slider rto">
            <VueSlickCarousel
              ref="property2"
              v-if="property.rent.length"
              v-bind="{
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,
                autoplay: false,
                speed: 500,
                autoplaySpeed: 5000,
                variableWidth: false,
                responsive: [
                  {
                    breakpoint: 1400,
                    settings: {
                      arrows: false,
                      slidesToShow: 1,
                      variableWidth: false
                    }
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      arrows: false,
                      slidesToShow: 1,
                      variableWidth: true
                    }
                  }
                ]
              }"
            >
              <template v-for="(property, index) in property.rent">
                <div class="item item-property" :key="index">
                  <figure>
                    <router-link
                      v-if="property.propertyType"
                      :to="`/property/${property.slug}#rent`"
                      class="type"
                      :style="
                        `background-color: ${property.propertyType.class}`
                      "
                    >
                      {{ property.propertyType.name }}
                    </router-link>
                    <a
                      @click="bookmark(property.id)"
                      class="cvr-bg pointer bookmark"
                      :class="{ marked: property.isBookmarked }"
                    ></a>
                    <router-link :to="`/property/${property.slug}#rent`">
                      <template
                        v-if="
                          property.mediaDescription === null ||
                            property.mediaDescription.length <= 0
                        "
                      >
                        <img src="/img/dummy/home/1.jpg" />
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            checkType(property.mediaDescription[0]) == 'video'
                          "
                        >
                          <video style="width: 100%" controls autoplay>
                            <source
                              :src="property.mediaDescription[0]"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </template>
                        <template v-else>
                          <img :src="property.mediaDescription[0]" />
                        </template>
                      </template>
                    </router-link>
                  </figure>
                  <div class="caption">
                    <h3>
                      <router-link :to="`/property/${property.slug}#rent`">
                        {{ property.name }}
                      </router-link>
                    </h3>
                    <div class="location cvr-bg-bf">
                      <span>{{ property.region }}, {{ property.city }}</span>
                      <span v-if="property.furnishedType">
                        {{ property.furnishedType.name }}
                      </span>
                    </div>
                    <!-- end of location -->
                    <strong class="price">
                      <b>Rp. {{ property.ratesMonthly | convertToRupiah }}</b>
                      <small> / {{ $t("findProperty.Month") }}</small>
                    </strong>
                    <div class="amen">
                      <span
                        class="cvr-bg-bf ic-bed"
                        v-if="showBedAndBath(property.propertyTypeId)"
                      >
                        {{ property.numBedroom }}
                      </span>
                      <span
                        class="cvr-bg-bf ic-bath"
                        v-if="showBedAndBath(property.propertyTypeId)"
                      >
                        {{ property.numBathroom }}
                      </span>
                      <span class="cvr-bg-bf ic-land">
                        {{ property.landSize | convertToRupiah }} m<sup>2</sup>
                      </span>
                      <span class="cvr-bg-bf ic-build">
                        {{ property.buildingSize | convertToRupiah }} m
                        <sup>2</sup>
                      </span>
                    </div>
                    <!-- end of amen -->
                  </div>
                </div>
                <!-- end of item -->
              </template>
            </VueSlickCarousel>
            <!--end of slick -->
            <div class="slidenav">
              <a
                class="prev poinetr cvr-bg"
                @click="$refs.property2.prev()"
              ></a>
              <a
                class="next pointer cvr-bg"
                @click="$refs.property2.next()"
              ></a>
            </div>
          </div>
          <!-- end of slider -->
          <div class="action">
            <router-link to="/find-property?transactionType=1" class="button">
              {{ $t("home.section4.Button") }}
            </router-link>
          </div>
        </div>
        <!-- end of wrapper -->
      </div>
      <div class="list">
        <div class="wrapper">
          <h2 class="clearfix">
            {{ $t("home.section4.Title3") }}
            <router-link to="/find-property?transactionType=3">
              {{ $t("home.section4.Link") }}
            </router-link>
          </h2>
          <div class="slider rto">
            <VueSlickCarousel
              ref="property3"
              v-if="property.buy.length"
              v-bind="{
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,
                autoplay: false,
                speed: 500,
                autoplaySpeed: 5000,
                variableWidth: false,
                responsive: [
                  {
                    breakpoint: 1400,
                    settings: {
                      arrows: false,
                      slidesToShow: 1,
                      variableWidth: false
                    }
                  },
                  {
                    breakpoint: 768,
                    settings: {
                      arrows: false,
                      slidesToShow: 1,
                      variableWidth: true
                    }
                  }
                ]
              }"
            >
              <template v-for="(property, index) in property.buy">
                <div class="item item-property" :key="index">
                  <figure>
                    <router-link
                      v-if="property.propertyType"
                      :to="`/property/${property.slug}#buy`"
                      class="type"
                      :style="
                        `background-color: ${property.propertyType.class}`
                      "
                    >
                      {{ property.propertyType.name }}
                    </router-link>
                    <a
                      @click="bookmark(property.id)"
                      class="cvr-bg pointer bookmark"
                      :class="{ marked: property.isBookmarked }"
                    ></a>
                    <router-link :to="`/property/${property.slug}#buy`">
                      <template
                        v-if="
                          property.mediaDescription === null ||
                            property.mediaDescription.length <= 0
                        "
                      >
                        <img src="/img/dummy/home/1.jpg" />
                      </template>
                      <template v-else>
                        <template
                          v-if="
                            checkType(property.mediaDescription[0]) == 'video'
                          "
                        >
                          <video style="width: 100%" controls autoplay>
                            <source
                              :src="property.mediaDescription[0]"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </template>
                        <template v-else>
                          <img :src="property.mediaDescription[0]" />
                        </template>
                      </template>
                    </router-link>
                  </figure>
                  <div class="caption">
                    <h3>
                      <router-link :to="`/property/${property.slug}#buy`">
                        {{ property.name }}
                      </router-link>
                    </h3>
                    <div class="location cvr-bg-bf">
                      <span>{{ property.region }}, {{ property.city }}</span>
                      <span v-if="property.furnishedType">
                        {{ property.furnishedType.name }}
                      </span>
                    </div>
                    <!-- end of location -->
                    <strong class="price price-buy">
                      <em>Rp. {{ property.rates | convertToRupiah }}</em>
                    </strong>
                    <div class="amen">
                      <span
                        class="cvr-bg-bf ic-bed"
                        v-if="showBedAndBath(property.propertyTypeId)"
                      >
                        {{ property.numBedroom }}
                      </span>
                      <span
                        class="cvr-bg-bf ic-bath"
                        v-if="showBedAndBath(property.propertyTypeId)"
                      >
                        {{ property.numBathroom }}
                      </span>
                      <span class="cvr-bg-bf ic-land">
                        {{ property.landSize | convertToRupiah }} m<sup>2</sup>
                      </span>
                      <span class="cvr-bg-bf ic-build">
                        {{ property.buildingSize | convertToRupiah }} m
                        <sup>2</sup>
                      </span>
                    </div>
                    <!-- end of amen -->
                  </div>
                </div>
                <!-- end of item -->
              </template>
            </VueSlickCarousel>
            <!--end of slick -->
            <div class="slidenav">
              <a
                class="prev pointer cvr-bg"
                @click="$refs.property3.prev()"
              ></a>
              <a
                class="next pointer cvr-bg"
                @click="$refs.property3.next()"
              ></a>
            </div>
          </div>
          <!-- end of slider -->
          <div class="action">
            <router-link to="/find-property?transactionType=3" class="button">
              {{ $t("home.section4.Button") }}
            </router-link>
          </div>
        </div>
        <!-- end of wrapper -->
      </div>
      <!-- end of list -->
    </div>
    <!-- end of property -->

    <div class="copytxt has-slider">
      <div class="wrapper rowflex">
        <figure>
          <img :src="section3.image" />
          <div class="slider">
            <VueSlickCarousel
              v-if="section3.hasOwnProperty('carousel')"
              v-bind="{
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false,
                autoplay: true,
                speed: 800,
                fade: true,
                autoplaySpeed: 5000
              }"
            >
              <template v-for="(content, index) in section3.carousel">
                <div class="item" :key="index">
                  <div class="txt">
                    <p>{{ content.description }}</p>
                  </div>
                  <!--e nd of txt -->
                  <h3>{{ content.title }}</h3>
                </div>
              </template>
            </VueSlickCarousel>
          </div>
        </figure>
        <div class="caption">
          <h2>{{ section3.title }}</h2>
          <div class="txt" v-html="section3.description"></div>
          <!-- end of txt -->
          <div class="action">
            <router-link to="/market-my-properties" class="button">
              {{ $t("home.section5.Button") }}
            </router-link>
          </div>
          <!-- end of action -->
        </div>
        <!-- end of caption -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of copytxt -->

    <div class="service">
      <div class="wrapper">
        <h2>
          {{ $t("header.OtherServices") }}
        </h2>
        <div class="rowflex">
          <template v-for="(content, index) in section4">
            <div class="item" :key="index">
              <figure>
                <img :src="content.image" />
                <div class="hover">
                  <router-link
                    to="/construction"
                    class="button"
                    v-if="index == 0"
                  >
                    {{ $t("home.section2.Button2") }}
                  </router-link>
                  <router-link to="/mortgage" class="button" v-else>
                    {{ $t("home.section2.Button2") }}
                  </router-link>
                </div>
                <!-- end of hover -->
              </figure>
              <div class="caption">
                <h3>{{ content.title }}</h3>
                <div class="txt" v-html="content.description"></div>
                <!-- end of txt -->
              </div>
              <!-- end of caption -->
            </div>
          </template>
        </div>
        <!-- end of rowflex -->
      </div>
      <!-- end of wrapper -->
    </div>
    <!-- end of service -->

    <button
      data-src="#login-register"
      type="button"
      class="vhidden"
      data-fancybox
      ref="logreg"
    ></button>
  </div>
  <!-- end of inner home -->
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Home",
  components: { VueSlickCarousel },
  data() {
    return {
      slides: [],
      section1: {},
      section2: {},
      section3: {},
      section4: [],
      property: {
        rentToOwn: [],
        rent: [],
        buy: []
      },
      sliderRTO: [
        {
          title: "Cluster Eureka at Nevada",
          loc: "Padalarang, Bandung",
          image: 6
        },
        {
          title: "Cozy Apartment at Woodland Park",
          loc: "Setiabudi, South Jakarta",
          image: 7
        },
        {
          title: "3BR House at Golf Island PIK",
          loc: "PIK, North Jakarta",
          image: 8
        },
        {
          title: "Fancy 1BR Apartment at The Newton",
          loc: "Sudirman, South Jakarta",
          image: 9
        }
      ],
      form: {
        transactionType: 2,
        search: null,
        bedroom: null,
        propertyType: null,
        furnishedType: null,
        rentPeriod: null,
        province: null,
        city: null,
        area: null,
        status: [],
        bathroom: [],
        carlot: [],
        minRentPrice: null,
        maxRentPrice: null,
        minSalesPrice: null,
        maxSalesPrice: null,
        minLandSize: null,
        maxLandSize: null,
        minBuildingSize: null,
        maxBuildingSize: null
      },
      propertyType: [],
      furnishedType: [],
      bedrooms: [
        {
          value: 1,
          name: 1
        },
        {
          value: 2,
          name: 2
        },
        {
          value: 3,
          name: 3
        },
        {
          value: "gte:4",
          name: "4+"
        }
      ],
      provinces: [],
      cities: [],
      areas: [],
      rentPeriod: []
    };
  },
  computed: {
    disableBedRoom() {
      if (
        this.form.propertyType == 1 || // House
        this.form.propertyType == 2 || // Apartment
        this.form.propertyType == 8 || // SOHO
        this.form.propertyType == 9 || // Vila
        this.form.propertyType === null
      ) {
        return false;
      } else {
        return true;
      }
    },
    bathroomParam() {
      if (this.form.bathroom.length > 0) {
        return `in:${this.form.bathroom.join(",")}`;
      } else {
        return null;
      }
    },
    carlotParam() {
      if (this.form.carlot.length > 0) {
        return `in:${this.form.carlot.join(",")}`;
      } else {
        return null;
      }
    },
    statusParam() {
      if (this.form.status.length > 0) {
        return `in:${this.form.status.join(",")}`;
      } else {
        return null;
      }
    }
  },
  watch: {
    disableBedRoom(newVal) {
      if (newVal === true) {
        this.form.bedroom = null;
      }
    }
  },
  mounted() {
    this.getContent();
    this.getPropertyType();
    this.getFurnishedType();
    this.getRentPeriod();
    this.getProvinces();
  },
  methods: {
    showNext() {
      this.$refs.rtoSlider.next();
    },
    getPropertyType() {
      this.$axios
        .get(`/misc/property-types`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.propertyType = response.data.data;
        });
    },
    getFurnishedType() {
      this.$axios.get(`/misc/furnished-types`).then(response => {
        this.furnishedType = response.data.data;
      });
    },
    getRentPeriod() {
      this.$axios.get(`/misc/rent-periods`).then(response => {
        this.rentPeriod = response.data.data;
      });
    },
    getProvinces() {
      this.$axios.get(`/misc/provinces`).then(response => {
        this.provinces = response.data.data.filter(item => item !== null);
      });
    },
    getCities() {
      this.form.city = null;
      this.$axios.get(`/misc/cities/${this.form.province}`).then(response => {
        this.cities = response.data.data;
      });
    },
    getAreas() {
      this.form.area = null;
      this.$axios.get(`/misc/postal-codes/${this.form.city}`).then(response => {
        this.areas = response.data.data;
      });
    },
    getContent() {
      this.$axios
        .get(`/pages/home`, {
          params: {
            lang: this.$store.state.locale.lang
          }
        })
        .then(response => {
          this.slides.push(response.data.data.headerSection.rent);
          this.slides.push(response.data.data.headerSection.rentToOwn);
          this.slides.push(response.data.data.headerSection.buy);
          this.section1 = response.data.data.section1;
          this.section2 = response.data.data.section2;
          this.section3 = response.data.data.section3;
          this.section4 = response.data.data.section4;
          this.property.rent = response.data.data.quickRent;
          this.property.rentToOwn = response.data.data.quickRentToOwn;
          this.property.buy = response.data.data.quickBuy;
        })
        .then(() => {
          this.$refs.slickCategory.goTo(1);
        });
    },
    formatRupiah(model) {
      const angka = this.convertToAngka(this.form[model]);
      const rupiah = this.convertToRupiah(angka, false);
      this.form[model] = rupiah;
    },
    resetPopUpForm() {
      this.form.province = null;
      this.form.city = null;
      this.form.area = null;
      this.form.status = [];
      this.form.bathroom = [];
      this.form.carlot = [];
      this.form.minSalesPrice = null;
      this.form.maxSalesPrice = null;
      this.form.minRentPrice = null;
      this.form.maxRentPrice = null;
      this.form.minLandSize = null;
      this.form.maxLandSize = null;
      this.form.minBuildingSize = null;
      this.form.maxBuildingSize = null;
    },
    bookmark(propertyId) {
      if (this.$store.state.userdata) {
        this.$axios
          .post("/properties/bookmarks", {
            id: propertyId
          })
          .then(response => {
            this.$swal({
              icon: "success",
              text: response.data.message
            });
            this.getContent();
          });
      } else {
        this.$refs.logreg.click();
      }
    }
  }
};
</script>

<style scoped>
.item {
  margin-left: 0 !important;
}
.toleft {
  left: 0 !important;
  right: auto !important;
}
input.price {
  width: 165px !important;
  margin-left: 42px !important;
  padding-right: 0px !important;
}
select:disabled {
  cursor: not-allowed !important;
}
.additional-filter {
  height: 32px;
  line-height: 32px;
  color: #262626;
  font-size: 14px;
  font-family: "Montserrat";
}
.additional-filter::after {
  background-image: none !important;
}
</style>
